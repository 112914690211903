require("alpinejs/dist/alpine-ie11");
require("@openfonts/exo-2_cyrillic");
const Swiper = require("swiper/js/swiper");
const priorityNav = require("priority-nav");

const SimpleScrollbar = require('simple-scrollbar');
require('simple-scrollbar/simple-scrollbar.css');

// import "lite-youtube-embed";
import "./stack-cards";
import "./ui";

import "../styles/main.scss";

document.addEventListener("DOMContentLoaded", () => {
  const $ = (selector) => {
    return document.querySelectorAll(selector);
  };

  if ($(".hero-swiper").length) {
    new Swiper(".hero-swiper", {
      slidesPerView: 1,
      pagination: {
        el: ".hero-swiper__pagination",
        clickable: true,
        type: "bullets",
        bulletClass: "hero-swiper__bullet",
        bulletActiveClass: "hero-swiper__bullet--active",
        autoplay: {
          delay: 7000,
        },
      },
    });
  }

  // if($('.priority-pricing').length) {
  //   if(wWidth < 1200) {
  //     let navPricing = priorityNav.init({
  //       mainNavWrapper: ".priority-pricing",
  //       navDropdownLabel: "Еще",
  //       navDropdownBreakpointLabel: "Направления"
  //     });
  //   }
  // }
});

window.onload = function () {
  const wWidth = document.documentElement.clientWidth;

  if ($(".priority-header").length) {
    priorityNav.init({
      mainNavWrapper: ".priority-header",
      navDropdownLabel: "Еще",
      navDropdownBreakpointLabel: "Основное меню",
    });
  }

  if ($(".reviews-swiper").length) {
    new Swiper(".reviews-swiper", {
      slidesPerView: 3,
      spaceBetween: 32,
      autoHeight: true,
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      navigation: {
        prevEl: ".reviews-swiper__prev",
        nextEl: ".reviews-swiper__next",
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        992: {
          slidesPerView: 2,
        },
        1280: {
          slidesPerView: 3,
        },
      },
    });
  }

  if ($(".js-intro-page-swiper").length) {
    const introPageSwiper = new Swiper(".js-intro-page-swiper", {
      spaceBetween: 16,
      navigation: {
        prevEl: ".js-intro-page-swiper__prev",
        nextEl: ".js-intro-page-swiper__next",
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: "auto",
        },
      },
      on: {
        init: function (e) {
          // Добавляем активный класс последнему элементу

          this.snapGrid = [...this.slidesGrid];
        },
      },
    });

    introPageSwiper.init();
  }

  if ($(".js-steps-swiper").length) {
    new Swiper(".js-steps-swiper", {
      slidesPerView: 1,
    });
  }

  if ($(".intro").length) {
    const introThumbs = new Swiper(".intro-thumbs", {
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    });

    new Swiper(".intro-gallery", {
      slidesPerView: 1,
      thumbs: {
        swiper: introThumbs,
      },
      navigation: {
        prevEl: ".intro-swiper__prev",
        nextEl: ".intro-swiper__next",
      },
      on: {
        init: function () {
          this.update();
        },
      },
    });
  }

  if ($(".certificates-swiper").length) {
    new Swiper(".certificates-swiper", {
      slidesPerView: 4,
      spaceBetween: 32,
      navigation: {
        prevEl: ".certificates-swiper__prev",
        nextEl: ".certificates-swiper__next",
      },
      breakpoints: {
        320: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: 4,
        },
      },
    });
  }

  if ($(".js-fullwidth-swiper")) {
    new Swiper(".js-fullwidth-swiper", {
      slidesPerView: "auto",
      spaceBetween: 32,
    });
  }

};
