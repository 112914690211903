function megaMenu() {
  return {
    isOpen: false,
    primary: "tab-0",
    secondary: window.innerWidth > 640 ? 'tab-0-0' : null,

    activeClassTab(tab) {
      return this.primary === `tab-${tab}` ? "text-azure" : "text-bali";
    },
    activeClassTabSubMenu(tab, current) {
      return this.secondary === `tab-${tab}-${current}` ? "bg-white font-bold mega-menu__tab--active" : "";
    },

    toggle() {
      this.isOpen = !this.isOpen;
    },
    changeTab(tab) {
      this.primary = `tab-${tab}`;
      // this.secondary = null;
    },
    changeTabSubMenu(tab, current) {
      this.secondary = `tab-${tab}-${current}`;
    }
  };
}

function swiper() {
  return {
    update(swiperEl) {
      this.$nextTick(() => {
        document.querySelector(swiperEl).swiper.update();
      });
    }
  };
}

function sliding() {
  return {
    isOpen: false,
    toggleText(show = "Подробнее", hide = "Скрыть описание") {
      return this.isOpen ? hide : show;
    },
    toggleView() {
      this.isOpen = !this.isOpen;
    }
  };
}

function tabs() {
  return {
    active: "tab-0",
    activeClassTab(tabIndex) {
      return this.active === `tab-${tabIndex}`
        ? "bg-azure text-white"
        : "hover:bg-catskill";
    },
    changeTab(tabIndex) {
      console.log(tabIndex);
      this.active = `tab-${tabIndex}`;
    },
    showTabPanel(tabIndex) {
      return this.active === `tab-${tabIndex}`;
    }
  };
}

function steps() {
  return {
    active: "slide-0",
    activeClassCircle(slideIndex, totalItems) {
      return [
        this.active === `slide-${slideIndex}`
          ? "bg-azure text-white"
          : "text-azure",
        slideIndex === totalItems - 1 ? "steps__circle--last" : ""
      ];
    },
    activeClassText(slideIndex) {
      return this.active === `slide-${slideIndex}`
        ? "text-persian"
        : "text-bali";
    },
    changeSlide(slideIndex) {
      this.active = `slide-${slideIndex}`;
      this.$refs.stepsSwiper.swiper.slideTo(slideIndex);
    }
  };
}

window.UI = {
  megaMenu,
  swiper,
  sliding,
  tabs,
  steps
};
